<template>
  <AppPageCard>
    <template #card-title> Teacher Away Assignment </template>
    <div>
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        {{ $t("EMPLOYEE.EMP") }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ getTitle() }}
      </span>
      <IndividualEmployeeAwayEntitlementTable
        :is-busy="isBusy"
        :items="items"
        :pagination="pagination"
        @onClickEntitlement="showModal"
      ></IndividualEmployeeAwayEntitlementTable>

      <TeacherIndividualAwayAssignmentModal
        :assigned-away="form"
        :is-busy="isBusy"
        @formSubmitted="fetch"
      ></TeacherIndividualAwayAssignmentModal>
    </div>
  </AppPageCard>
</template>

<script>
import { mapGetters } from "vuex";
import IndividualEmployeeAwayEntitlementTable from "@/modules/school/components/management/away/entitlement/IndividualEmployeeAwayEntitlementTable";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import TeacherIndividualAwayAssignmentModal from "@/modules/school/components/management/away/assignment/teacher/TeacherIndividualAwayAssignmentModal";
export default {
  name: "TeacherIndividualAwayAssignmentPage",
  components: {
    TeacherIndividualAwayAssignmentModal,
    AppPageCard,
    IndividualEmployeeAwayEntitlementTable,
  },
  data() {
    return {
      isBusy: false,
      selectedAway: this.form ?? {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    getTitle() {
      return this.$route.query.name;
    },

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherAwayIndividualEntitlementList", {
          id: this.$route.query.id,
        })
        .finally(() => (this.isBusy = false));
    },

    /**
     *
     * @param data
     */
    showModal(data) {
      console.log("Data ", data);

      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherAwayIndividualEntitlementForm", {
          id: data.id,
        })
        .finally(() => (this.isBusy = false));

      this.$bvModal.show("away-page-assignment-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherAwayIndividualEntitlementList",
      pagination: "getTeacherAwayIndividualEntitlementListPagination",
      form: "getTeacherAwayIndividualEntitlementForm",
    }),
  },
};
</script>

<style scoped></style>
